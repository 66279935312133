<template>
    <div class="main_container">
        <NavBar/>
        <div class="privacy_content">
            <div class="privacy_title">
                PRIVACY POLICY
            </div>
            <div class="privacy_sub-content">
                <div class="privacy_sub-item">
                    <span>COMPANY: </span>
                    REZI LEADS INC. and its affiliated entities (“Company”, “us”, “our”, and “we”)
                </div>
                <div class="privacy_sub-item">
                    <span>CONTACT: </span>
                    SUPPORT@REZILEADS.COM
                </div>

                <div class="privacy_sub-item">
                    <span>COMPANY WEBSITE:  </span>
                    The website located at rezileads.com (together with any websites on related domains or subdomains,
                    the “Site”).
                </div>
                <div class="privacy_sub-item">
                    <span>COMPANY APPS:  </span>
                    The mobile or online application(s) entitled “Rezi Leads CRM” (together with any other applications
                    or platforms available via the Site or published by the Company, collectively, the “App”).
                </div>
            </div>

            <div class="privacy_using">
                <span>BY USING THE SITE, THE APP, OR ANY OF COMPANY’S PRODUCTS OR SERVICES, YOU ARE EXPRESSLY AGREEING AND CONSENTING TO THE TERMS OF THIS PRIVACY POLICY! </span>
                &nbsp;PLEASE READ IT CAREFULLY!
            </div>

            <div class="privacy_info">
                This Privacy Policy (this “Policy”) is a legal agreement between you and your business, if applicable,
                on the one hand (together, “you” or “your”), and Company (as defined above) on the other hand. This
                Policy describes how we may collect, store, use and share certain information about you and your usage
                of the Site, the App and related products and services (this information is referred to as your “User
                Information” throughout this Policy).
            </div>

            <div class="privacy_points">
                <ol>
                    <li>
                        <span>WHEN USER INFORMATION IS COLLECTED.</span> Company collects your information during your
                        access or
                        use of (i) any websites or web application provided, published, developed or made available by
                        the Company, including the Site; (ii) any mobile or online applications provided, published,
                        licensed, developed or made available by the Company, including the App; and (iii) any feature,
                        content, software, hardware, services or other products available on or through the Site or the
                        App or otherwise provided by the Company (together with the Site and the App, the “Services”).
                        You access and use of the Services is conditioned on your providing us with any requested User
                        Information, and your refusal to provide requested User Information may prevent Company from
                        providing certain Services.
                    </li>
                    <li>
                        <span>WHAT INFORMATION IS COLLECTED.</span>
                        <ol>
                            <li>
                                <span>Personally Identifiable Information.</span> Company may collect any
                                personally-identifiable
                                information that you provide in connection with any Services, including, without
                                limitation: your name, email address, mailing address, phone number, photograph,
                                birthdate, passport, driver’s license, government-issued identification numbers, and
                                other similar information provided with respect to your business or its employees,
                                officers, representatives or affiliates, and certain historical, contact, and
                                demographic information about you.
                            </li>
                            <li>
                                <span>Device Information.</span> Company may collect information about your device when
                                you
                                access any Services, including hardware model, operating system and version, unique
                                device identifier, mobile network information, and information about the device’s
                                interaction with the Services, as well as GPS information and information about the
                                location of your device when using any Services.
                            </li>
                            <li>
                                <span>Payment Information.</span> Company may collect information provided in connection
                                with you
                                making, accepting, requesting or recording payments, credits or money transfers through
                                any Services, including: payment card numbers, bank accounts information, when and where
                                the transactions occur, the names of the transacting parties, a description of the
                                transactions, the payment or transfer amounts, billing and shipping information, and the
                                devices and payment methods used to complete the transactions.
                            </li>
                            <li>
                                <span>Tax Information. </span>Company may collect information regarding your tax
                                information, including states of residence, withholding allowances and tax filing
                                status.
                            </li>
                            <li>
                                <span>Purchase Information. </span>Company may collect information and data about
                                your purchase information and preferences, including products and services purchased,
                                amounts paid, frequency of purchases, location of purchases, and similar information.
                            </li>
                            <li>
                                <span>Usage Information. </span>Company may collect information and data about how you
                                use the Services, including access time, Services accessed, browser type and language,
                                Internet Protocol (“IP”) address, other applications on your device, webpages and
                                applications viewed and used, time spent on webpages and applications, links clicked,
                                and conversion information (e.g., transactions entered into).
                            </li>
                            <li>
                                <span>Aggregated Data. </span>Company may collect aggregated data about your or your use
                                of Services, or any other aggregated form of the other types of User Information set
                                forth above. Aggregated data that we collect cannot be specifically associated with you
                                individually, unlike most of the other forms of User Information.
                            </li>
                        </ol>
                    </li>
                    <li>
                        <span>HOW USER INFORMATION IS COLLECTED. </span>
                        <ol>
                            <li>
                                <span>Information Given By You. </span>
                                You provide certain User Information when you register for your account with the
                                Services and as you use the Services. We consider all such information voluntarily
                                provided.
                            </li>
                            <li>
                                <span>Automatic Collection. </span>User Information can be recorded and collected by
                                software or processes that run automatically on your computer or device or on Company’s
                                servers as you use the Services.
                            </li>
                            <li>
                                <span>Cookies. </span>User Information may be collected by sending cookies to your
                                device. Cookies are small data files that are stored on your hard drive or in your
                                device memory when you use the Services. Among other things, cookies support the
                                integrity of the Company registration process, retain your preferences and account
                                settings, and help evaluate and compile aggregated statistics about your activity.
                                Company may use both session cookies and persistent cookies. A session cookie disappears
                                after you close your browser. A persistent cookie remains after you close your browser
                                and may be used by the browser on subsequent uses of the Services. Company may link the
                                information stored in cookies to your User Information submitted while using the
                                Services. You can typically remove and reject cookies from our Site with your browser
                                settings. Many browsers are set to accept cookies until you change your settings. If you
                                remove or reject our cookies, it could affect how the Services are provided.
                            </li>
                            <li>
                                <span>Web Beacons. </span>User Information may also be collected by using web beacons.
                                Web beacons are electronic images that may be used in connection with the Services.
                                Company may use web beacons to deliver cookies, track the number of visits to the
                                Services, understand Service usage and effectiveness, and determine whether an email has
                                been opened and acted upon.
                            </li>
                            <li>
                                <span>Collection from Third Parties. </span>User Information is also collected from
                                third parties that already have access to certain of your User Information, including
                                third-party verification services, credit bureaus, mailing list providers, and publicly
                                available sources. User Information may also include your data and content contained
                                within a third-party service links or integrates with the Services. In some
                                circumstances, where lawful, this information may include your government-issued
                                identification number. These third parties may use cookies, web beacons, and other
                                technologies to collect User Information. This Privacy Policy does not apply to, and
                                Company is not responsible for, third-party cookies, web beacons, or other tracking
                                technologies, which are covered by such third parties’ privacy policies. Company
                                continually evaluates analytics services to use in addition its own, and Company may
                                update this policy in the future to reflect Company’s ongoing use of said services.
                            </li>
                        </ol>
                    </li>
                    <li>
                        <span>HOW COLLECTED USER INFORMATION IS USED. </span>
                        <ol>
                            <li>
                                <span>As Permitted by Law. </span> Company may use, publish, store, duplicate,
                                manipulate
                                and share User Information in any way not otherwise prohibited by applicable privacy
                                laws.
                            </li>
                            <li>
                                <span>Provide Services. </span> User Information is stored and used so that you can
                                verify your identify and save your preferences, profile and information in connection
                                with creating, using and accessing your account with the Services, and to deliver,
                                operate, provide, maintain, enhance, personalize, tailor and facilitate your use of the
                                Services.
                            </li>
                            <li>
                                <span>Improve Services.</span> User Information is also used to develop new products and
                                Services, provide optimization, statistical analysis, and improvements for existing
                                Services, and to improve web design and functionality.
                            </li>
                            <li>
                                <span>Communication Purposes. </span> User Information is used to deliver technical
                                notices,
                                security alerts, support messages, administrative notices and alerts, and communications
                                relevant to your use of the Service, as well as to inform you about software
                                compatibility issues, send news or information, conduct surveys and collect feedback.
                                User Information is also used to communicate with you about products, services,
                                contests, promotions, discounts, incentives, gift cards, loyalty programs, and rewards,
                                based on your communication preferences and applicable privacy laws.
                            </li>
                            <li>
                                <span>Facilitate Payments. </span> Using and storing your User Information is necessary
                                for us to
                                be able to process or record payment transactions or money transfers.
                            </li>
                            <li>
                                <span>Track and Display Consumer Behavior. </span> User Information is stored so that
                                the Services
                                can track historical transactions, payments, invoices, receipts and past order
                                information. User Information is also used to display current, ongoing, pending or
                                requested transactions or orders.
                            </li>
                            <li>
                                <span>For Automated Decisions. </span> User Information may be used by the Services to
                                automatically trigger certain actions by the Services, including targeted advertisements
                                or content, directing you to specific products or offerings, suggesting various actions
                                or offerings based on behavior and preferences, etc.
                            </li>
                            <li>
                                <span>Legal Compliance. </span> User Information is stored so that we can comply with
                                applicable
                                laws and regulations, including the privacy laws and anti-money laundering laws.
                            </li>
                            <li>
                                <span>Aggregated Data Collection. </span> User Information is collected and aggregated
                                to monitor
                                aggregate usage and web traffic routing of all users of the Services, as well as to
                                generate aggregated statistics about purchasing behavior of different demographics and
                                populations.
                            </li>
                            <li>
                                <span>Dispute Resolution; Contract Enforcement. </span> User Information may be used to
                                resolve
                                disputes; collect fees; provide assistance with problems with Services; protect Company
                                rights or property or the security or integrity of the Services; enforce the Terms of
                                Use and other terms and conditions that govern use of the Services; and investigate,
                                detect and prevent fraud, security breaches, and other potentially prohibited or illegal
                                activities.
                            </li>
                        </ol>
                    </li>
                    <li>
                        <span>HOW COLLECTED USER INFORMATION IS SHARED. </span>
                        <ol>
                            <li>
                                <span>As Permitted by Law.</span> Company may share User Information in any way not
                                otherwise prohibited by applicable privacy laws.
                            </li>
                            <li>
                                <span>With Consent. </span> User Information may be shared with other parties with
                                your consent, such as for marketing uses that you authorize or consent to.
                            </li>
                            <li>
                                <span>Facilitate Third Party Services. </span> Company, like many businesses,
                                sometimes hire other companies or individuals to perform certain business-related
                                functions and will need to share certain User Information for such parties to perform
                                such functions. These third-party functions may include: anonymous site metrics,
                                analytics services, payment processing, point of sale services, task management
                                services, delivery of loyalty programs, management of gift cards, creation and delivery
                                of promotional materials, product support and maintenance, order fulfillment and
                                shipment/delivery of products and Services to users, mailing services, maintaining
                                databases and other features or services included in or necessary for the Services. When
                                Company employs another party to perform a function of this nature, we only provide them
                                with the information that they need to perform their specific function.
                            </li>
                            <li>
                                <span>Delivery of Targeted Services and Ads. </span> User Information may also be
                                shared or licensed to third-parties without your consent to deliver targeted and
                                non-targeted third-party content and advertisements in connection with the Services as
                                well as un-related services, websites and applications.
                            </li>


                            <li>
                                <span>For Legal and Safety Purposes. </span> User Information may be shared for legal,
                                protection, and
                                safety purposes, such as to comply with laws, respond to lawful requests and legal
                                processes, protect the rights and property of Company against competitors or claimants,
                                and
                                enforce our agreements, policies, and terms of use.
                            </li>
                            <li>
                                <span>Sale of User Information.</span>User Information may be sold, transferred or
                                assigned to
                                third-parties without your consent for their own uses, subject to applicable privacy
                                laws.
                            </li>
                            <li>
                                <span>Business Transfers.</span>As we develop our business, we might sell or buy
                                businesses or
                                assets. In the event of a corporate sale, merger, reorganization, dissolution or similar
                                event, User Information may be part of the transferred assets.
                            </li>
                            <li>
                                <span>Affiliated Entities, Agents, Consultants and Related Third Parties.</span>We may
                                also share
                                your User Information with our affiliated entities for purposes consistent with this
                                Policy.
                            </li>
                        </ol>
                    </li>
                    <li>
                        <span>HOW COLLECTED USER INFORMATION IS PROTECTED.  </span>
                        <ol>
                            <li>
                                <span>Protective Measures. </span> Company takes commercially reasonable measures,
                                including administrative, technical, and
                                physical safeguards, to (i) protect User Information from loss, theft, misuse, and
                                unauthorized access, disclosure, alteration, and destruction, (ii) ensure the security,
                                confidentiality, and integrity of the User Information, (iii) protect against any
                                anticipated threats or hazards to the security or integrity of the User Information,
                                (iv)
                                protect against unauthorized access to, or unauthorized use or disclosure of, the User
                                Information, and (v) take such security measures required by any applicable privacy
                                laws.
                            </li>
                            <li>
                                <span>Third Party Partners and Employees. </span> Company may, by itself or using
                                third-party service
                                providers, hold, process and store User Information, including in the United States,
                                Japan,
                                the European Union and other countries. Company restricts access to User Information to
                                those employees, contractors, and agents who need to know that information for purposes
                                of
                                performing their obligations to Company or to you, and who are subject to contractual
                                confidentiality obligations, and who may be disciplined or terminated if they fail to
                                meet
                                these obligations. Company third-party service providers store and transmit User
                                Information
                                in compliance with appropriate confidentiality and security measures.
                            </li>
                            <li>
                                <span>Security Breach. </span> Company cannot guarantee that unauthorized third parties
                                will never be able to defeat Company security measures or use User Information for
                                improper purposes. In the event that any User Information in Company’s possession or
                                under Company’s control is compromised as a result of a security breach, Company shall
                                give prompt notice to you, with full particulars, and shall immediately commence a
                                thorough investigation of any such incident.
                            </li>
                            <li>
                                <span>Company Not Liable for Breach. </span>Company will not be liable for any damages,
                                claims,
                                liability, or causes of action that arise as a result of any such security breach.
                            </li>
                        </ol>
                    </li>
                    <li>
                        <span>EXCLUSIONS. </span>This Policy shall not apply to (i) information posted by you to any
                        public areas of the Services, such as bulletin boards, chat room, community pages or comment
                        areas, (ii) any ideas for new products or modifications to existing products, and (iii) other
                        unsolicited submissions (collectively, “Unsolicited Information”). All Unsolicited Information
                        shall be deemed to be non-confidential and voluntarily given, and Company shall be free to
                        reproduce, use, disclose, and distribute such Unsolicited Information to others without
                        limitation or attribution.
                    </li>
                    <li>
                        <span>CHOICES; INTERNATIONAL PRIVACY LAWS; RETENTION.</span>
                        <ol>
                            <li>
                                <span>Right to be Informed; Access. </span> We strive to be completely transparent in
                                how we are using your personally identifiable information (your “Personal Data”). You
                                have the right to know exactly what Personal Data is held about you and how it is
                                processed. Information about how your Personal Data is shared may be requested by
                                contacting us through our contact information set forth above.
                            </li>
                            <li>
                                <span>Right of Rectification. </span> You are entitled to correct your Personal Data if
                                it is inaccurate or incomplete. Corrections to your Personal Data may be requested by
                                contacting us through our contact information set forth above.
                            </li>
                            <li>
                                <span>Right of Erasure or to Decline to Share. </span>You have the right to have your
                                Personal Data deleted or removed for any or no reason, and you have the right to decline
                                to share certain Personal Data with us. Deletion of your Personal Data may be requested
                                by contacting us through our contact information set forth above. However, if you choose
                                to exercise either of these rights, your ability to use and access our websites, apps,
                                products and services may be impacted because various features require your Personal
                                Data to function correctly.
                            </li>
                            <li>
                                <span>Right to Data Portability. </span> You have the right to retain and reuse any
                                Personal Data for your own purpose. A copy of your Personal Data may be requested by
                                contacting us through our contact information set forth above.
                            </li>
                            <li>
                                <span>Right to Object; Restrict Processing. </span> In certain circumstances, you are
                                entitled to object to the use of your Personal Data. This includes when your Personal
                                Data is used for the purpose of direct marketing, scientific and historical research or
                                the performance of a task in the public interest, when such use has a consequence with a
                                legal bearing on yourself, or when such use is for the purpose of automatic decision
                                making that has a material impact outside your use of our websites, apps, products or
                                services. Objecting to a use of your Personal Data may be requested by contacting us
                                through our contact information set forth above. You may not object if our use of your
                                Personal Data is otherwise permitted by applicable law.
                            </li>
                            <li>
                                <span>International Privacy Laws. </span> If you are using any of the websites, apps,
                                products or services from outside the United States, please be aware that you are
                                sending information, including personally identifiable information and data, to the
                                United States where our servers are located. That information may then be transferred
                                within the United States or back out of the United States to other countries outside of
                                your country of residence, depending on the type of information and how it is stored or
                                used by us. These countries (including the United States) may not necessarily have data
                                protection laws as comprehensive or protective as those in your country of residence;
                                however, our collection, storage and use of your Personal Data will at all times
                                continue to be governed by this Privacy Policy. <br/>
                                For personally identifiable information received from the European Union, we also
                                certify that we adhere to the EU-US Privacy Shield principles of Notice, Choice, Onward
                                Transfers, Security, Data Integrity & Purpose Limitation, Access and Recourse, and
                                Enforcement.
                            </li>
                            <li>
                                <span>Data Retention. </span> We only retain your Personal Data for as long as your
                                account with us is active, and then for an additional limited period of time for as long
                                as we need it to fulfill the purposes for which we initially collected such information,
                                unless otherwise required by law or to protect our rights. For example, we may be
                                required to retain copies of certain information to comply with legal obligations, to
                                resolve disputes or enforce our agreements.
                            </li>
                        </ol>
                    </li>
                    <li>
                        <span>CHANGES TO POLICY.  </span>
                        <ol>
                            <li>
                                <span>Notification of Changes. </span> This Policy is subject to occasional revision,
                                and if we make any substantial changes, we may notify you through the Services, by
                                sending you an e-mail to the last e-mail address you provided to us (if any), and/or by
                                prominently posting notice of the changes on the Site or through your account. You are
                                responsible for providing us with your most current e-mail address. In the event that
                                the last e-mail address that you have provided us is not valid, or for any reason is not
                                capable of delivering to you the notice described above, our dispatch of the e-mail
                                containing such notice will nonetheless constitute effective notice of the changes
                                described in the notice.
                            </li>
                            <li>
                                <span>Effectiveness of Changes. </span> Any changes to this Policy will be
                                effective upon the earlier of fifteen (15) calendar days following our dispatch of an
                                e-mail notice to you (if applicable) or fifteen (15) calendar days following our posting
                                of notice of the changes on our Site. These changes will be effective immediately for
                                new users of the Services.
                            </li>
                            <li>
                                <span>Use and Acceptance of Policy.  </span> Continued use of the Services following
                                notice of changes to this Policy shall indicate your acknowledgement and acceptance of
                                such changes and agreement to be bound by the updated Policy.
                            </li>
                            <li>
                                <span>Last Revision Date.  </span> This Policy was last updated on the date indicated
                                above. Please review this policy periodically, and especially before you voluntarily
                                provide any User Information.
                            </li>
                        </ol>
                    </li>
                    <li>
                        <span>CONTACT INFORMATION.  </span> If you have any questions that aren’t addressed by
                        this Privacy Policy, please let us know! Our contact information can be found at the top of this
                        Privacy Policy. Use it to contact us for anything related to our use of your information,
                        including opting-out of sharing your information with others, updating your information, finding
                        out what information we have about you, or for anything that you feel violates any of your above
                        listed rights.
                    </li>

                </ol>
            </div>


        </div>
        <Footer/>
    </div>
</template>

<script>
    import NavBar from "../components/NavBar";
    import Footer from "../components/Footer";

    export default {
        name: "PrivacyPolicy",
        data() {
            return {}
        },
        components: {
            NavBar,
            Footer
        },
        methods: {}
    }
</script>

<style scoped lang="scss">
    .main {
        &_container {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
    }
    .privacy {
        &_content {
            width: 100%;
            padding: 100px 0 20px;
            display: flex;
            flex-direction: column;
            align-items: center;
            max-width: 1640px;
            margin: 0 auto;
        }
        &_title {
            padding: 30px 0;
            font-size: 36px;
            font-weight: 600;
            color: $darkBlue;
        }
        &_sub-content {
            padding: 15px 0;
        }
        &_sub-item {
            padding: 10px 0;
            font-size: 24px;
            & span {
                color: $main_blue;
                font-weight: 600;
                margin-right: 10px;
            }
        }
        &_using {
            padding: 26px 0;
            border-top: 2px solid $light_blue;
            border-bottom: 2px solid $light_blue;
            font-weight: 600;
            font-size: 28px;
            margin: 20px 0;
            & span {
                text-decoration: underline;
            }
        }
        &_info {
            padding: 15px 0;
            font-size: 18px;
            font-weight: 500;
        }
        &_points {
            padding: 10px 10px 40px;
            ol {
                list-style-type: none;
                counter-reset: item;
                margin: 0;
                padding: 0;
            }
            li {
                display: table;
                counter-increment: item;
                margin-bottom: 0.6em;
            }
            li:before {
                content: counters(item, ".") ". ";
                display: table-cell;
                padding-right: 0.6em;
                color: $light_blue;
                font-weight: 600;
            }
            li li {
                margin: 0;
            }
            li li:before {
                content: counters(item, ".") " ";
            }
            & li {
                padding: 10px 0;

                & span {
                    color: $light_blue;
                    font-weight: 600;
                }
            }

        }
    }

</style>